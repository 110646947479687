@import '__importable.scss';
.container {
	width: 100%;
	padding-inline: 0.8rem;

	@media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding-inline: 12.8rem;
	}
}

.heading {
	padding: 1.6rem;
	width: 100%;
	max-width: $max-width;
	margin: auto;
	grid-column: 1 / -1;
	border-bottom: 1px solid $gray-3;
}
