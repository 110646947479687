@import '__importable.scss';
.interactive-card{
	width: 100%;
    @media screen and (min-width: $media-sm) {
        height: 100%;
        min-width: auto;
    }
	&[data-size-block='micro'] {
		@media screen and (min-width: $media-md) {
			grid-column: span 1;
		}
	}
	@media screen and (min-width: $media-md) {
		grid-column: span 2;
	}
}

.interactive-card-double {
	width: 100%;
	grid-column: span 2;
	height: 25.6rem;
    @media screen and (min-width: $media-sm) {
        min-width: auto;
    }
	&[data-size-block='micro'] {
		@media screen and (min-width: $media-md) {
			grid-column: span 1;
		}
	}
    @media screen and (min-width: $media-md) {
		height: 100%;
    }
}