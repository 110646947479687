@import '__importable.scss';
.hero-container {
	padding: 0.8rem;
	flex-flow: row wrap;
	align-items: flex-start;
	
	>[class*='FilterRow'] {
		flex: 0 0 auto;
		padding: 0;
		margin-top: 0.8rem;
		margin-bottom: 1.2rem;
		align-self: center;
		width: 100%;
		position: unset;
		
		>div {
			padding: 0;

			[class*='Button'] {
				width: 100%;
			}
		}
	}
	
	&::after {
		content: '';
		flex: 1 0 100%;
		border-bottom: 1px solid $gray-5;
		
		@media screen and (min-width: $media-sm) {
			margin-top: 0.8rem;
		}
	}
	
	@media screen and (min-width: $media-sm) {
		padding: 1.6rem;

		>.title {
			flex: 1;
			max-width: 75%;
		}

		>[class*='FilterRow'] {
			margin: 0;
			width: max-content;
		}
	}

	@media screen and (min-width: $media-md) {
		padding-inline: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}
}

.extra-header {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));

	@media screen and (min-width: $media-md) {
		padding-block: 2.4rem !important;
	}
}

.hide-on-mobile {
	display: none;

	@media screen and (min-width: $media-md) {
		display: block;
	}
}