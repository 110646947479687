@import '__importable.scss';


.container {
	width: 100%;
	position: relative;
	z-index: $zindex-1;
	overflow: hidden;

	@include height-calculation;
}

.plp-carousel {
	border-radius: 0.8rem;
	border: 1px solid $gray-2;
}

.swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transition: 300ms ease opacity;
	transform: translate3d(0, 0, 0);
	bottom: 0.8rem;
	width: 100%;

	&::after {
		content: '';
		height: 2rem;
		width: 9rem;
		position: absolute;
		border-radius: 1.6rem;
		opacity: 0.5;
	}

	&.swiper-pagination-hidden {
		opacity: 0;
	}
}

.swiper-pagination-bullet {
	margin-inline: 0.4rem;
	height: 2.6rem;
	display: flex;
	justify-content: center;
	border-radius: $radius-6;
	align-items: center;
	position: relative;
	z-index: $zindex-2;
	&.plp{
		&::after{
			background: $blue-tint;
			border-color: $blue-tint;
		}
	}

	&::after {
		content: '';
		width: 0.8rem;
		height: 0.8rem;
		display: block;
		border-radius: $radius-6;
		background: $gray-0;
		position: relative;
		border: 1px solid $gray-0;
		opacity: 0.25;
	}

	&:only-child {
		display: none;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&.swiper-pagination-bullet-active {
		border-radius: $radius-6;
		position: relative;
		height: 0.8rem;
		&.plp{
			&::before{
				background: $blue-tint;
			}
			&:not(.progress-paused) {
				&::after {
					background-color: $blue-2;
				}
			}
		}

		&::before{
			content: '';
			background: white;
			opacity: 0.25;
			position: absolute;
			top:0;
			left:0;
			height: 0.8rem;
			border-radius: $radius-6;

		}

		&:not(.progress-paused) {
			&::after {
				content: '';
				position: relative;
				opacity: 1;
				top: 0;
				left: 0;
				height: 0.8rem;
				border-radius: $radius-6;
				background-color: $white;
			}
		}
	}
}

.swiper-pagination.progress-paused .swiper-pagination-bullet-active::after {
	animation-play-state: paused;
}

.swiper-pagination-lock {
	display: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
	z-index: $zindex-1;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-play-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 4rem;
	height: 4rem;
	padding: 0;
	margin-top: calc($swiper-navigation-size / -2);
	color: $blue-2;
	background-color: $gray-0;
	border: 1.5px solid $gray-2;
	border-radius: $radius-round;
	transition: background-color 300ms ease;
	z-index: $zindex-3;
	cursor: pointer;

	svg {
		height: 1.6rem;
		stroke: $gray-5;
	}

	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}

	&:hover {
		background-color: $gray-2;
		transition: background-color 300ms ease;
	}

	&:active {
		border: 1px solid $gray-3;
		background-color: $gray-2;
		transition: background-color 300ms ease;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&:focus {
		outline: none;
	}

	@media screen and (max-width: $mq-small) {
		display: none;
	}
}

.swiper-play-button {
	width: 3.2rem;
	height: 3.2rem;
	right: 1rem;
	left: auto;
	bottom: 3%;
	top: unset;

	svg {
		stroke: $blue-2;
		height: 2rem;
		width: 2rem;
		position: absolute;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	}

	&:active {
		border: 1px solid $gray-3;
	}

	&:focus-visible {
		box-shadow: unset;
		border: 1px solid $gray-3;
	}

	@media screen and (max-width: $mq-small) {
		display: inherit;
	}
	@media screen and (min-width: $mq-small) {
		right: auto;
		left: 3.2rem;
	}
}

.swiper-button-prev {
	right: 8rem;
	left: auto;
	bottom: 3%;
}

.swiper-button-next {
	right: 3.2rem;
	left: auto;
	bottom: 3%;
}

.hide {
	display: none;
}
