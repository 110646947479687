@import '__importable.scss';
.headingContainer {
	max-width: $max-width;
	height: 100%;
	margin: auto;

	@media screen and (min-width: $media-lg) {
		padding-left: 2.4rem;
	}

	@media screen and (min-width: $media-xxxl-max) {
		padding-left: unset;
	}

	&.is-half-height-active {
		height: 12.8rem;
		
		.logo {
			height: 8rem;
		}
	}
}

.container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.leftAlignContainer {
	@extend .container;

	@media screen and (min-width: $media-md) {
		max-width: 36rem;
		margin: 0;
		display: flex;
		justify-content: flex-start;
	}
}

.rightAlignLogo {
	position: relative;

	@media screen and (min-width: $media-md) {
		position: absolute;
		top: 50%;
		right: 6rem;
		translate: 0 -50%;
	}
}

.button {
	position: absolute;
	top: 0;
	left: 0;
}

.logo {
	height: 12rem;
	object-fit: contain;
	
	@media screen and (min-width: $media-sm-max) {
		height: 15rem;
	}
}
