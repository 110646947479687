@import '__importable.scss';
.container {
	position: sticky;
	top: $headerHeight;
	z-index: $zindex-3;
	background-color: $white;
}

.inner {
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
	padding: 0.8rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@include product-grid-padding;

	@media screen and (width < $media-md) {
		padding-inline: 0.8rem;
	}

	@media screen and (width >= $media-md) {
		padding-block: 1.6rem;
	}

}

.innerReverse {
	@extend .inner;
	flex-direction: row;
	align-items: flex-start;
	max-width: 100%;
	gap: 0.8rem;

	@media screen and (min-width: $media-sm) {
		flex-direction: row-reverse;
	}
}

.selectContainer {
	max-width: $max-width;
	width: 100%;
	margin: auto;
}

.heading {
	text-align: left;
	left: 0;
	position: relative;

	@media screen and (min-width: $media-sm) {
		position: absolute;
		text-align: center;
		left: 50% !important;
		translate: -50% 0;
	}
}
